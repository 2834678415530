@import '_partials/layout';
@import '_partials/pricing';
@import "_partials/schedule";
@import 'swiper/css';

html{

  body{
    overflow-x: hidden;
  }
  .bg-gradiant-color{
    background: linear-gradient(45deg , #5263EA, #CA3EDE);
    color: #fff
  }

  @media (max-width: 1024px) {
    .intercom-lightweight-app-launcher {
    bottom: 72px !important;
    }

    /*localhost*/
    .intercom-dfosxs {
    bottom: 72px !important;
    }

    iframe[name='intercom-notifications-frame'] {
    bottom: 124px !important;
    }
  }
}
